<template>
    <div class="re_min_page re_sizing re_paddingtb_24 re_paddinglr_16">
      <div class="title re_relative re_flex_center re_font_18">
          <van-icon @click="goBack(1)" class="icon" name="arrow-left" size="22" />
      </div>
      <div class="noneImg">
        <img src="../../../assets/appleTree/appleRulesLock.png" alt="">
        <p class="re_color_second">朋友设置了访问权限，暂无法查看TA的果园</p>
      </div>
  
      
    </div>
  </template>
  
  <script setup>
  const goBack = () => {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    if (isAndroid) {
        android.finish()
    }
    if (isiOS) {
        window.webkit.messageHandlers.AppModel.postMessage('finish')
    }
  }

  </script>
  <style lang="less">
  .liuliuTip{
    flex-direction: row-reverse;
    img{
      width: 210px;
      height: 24px;
    }
  }
  </style>
  <style lang="less" scoped>
  .title {
        width: 100%;
        font-weight: 600;
        z-index: 9;

        span {
            left: 10px;
        }

        .icon {
            position: absolute;
            left: 18px;
            top: 18px;
        }
    }
  .noneImg{
    text-align: center;
    padding-top: 160px;
    img{
      width: 168px;
      height: 168px;
    }
  }
  </style>